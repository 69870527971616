/* banner css starts */
.relative-anchor {
    position: relative;
}

.absolute-wrapper {
    position: absolute;
    right: 0;
}

.banner-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    color: var(--dark);
    /*--fontSize: clamp(15px, 3vw, 39px);*/
    --fontSize: 0;
}

.character {
    position: relative;
    color: transparent; /* characters for positioning only */

    /* test */
    /*height: calc(var(--fontSize) * 1.2);*/
    /*height: min-content;*/

    /* animations */
    width: auto;
    min-width: 0;
    transition: min-width 0.5s cubic-bezier(1, 0, 0, 1);
}

.letter-pillar {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    color: var(--dark);
    transition: 1s all 0.3s ease;
}

.letter {
    height: calc(var(--fontSize) * 1.2);
}