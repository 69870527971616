/* about section */

.section-about {
    background-color: var(--black);
    width: 100%;
    --boxWidth: min(25vw, 360px);
    --boxHeight: min(42vw, 600px);
    --thinBoxWidth: min(17vw, 250px);
    --gridGap: min(2vw, 30px);
    --heartSize: min(2.2vw, 32px);
    --fontSize: min(1.67vw, 24px);
}

.about-frame {
    display: grid;
    margin-left: 100px;
    width: calc(100vw - 100px);
    grid-template-columns: var(--boxWidth) var(--boxWidth) var(--boxWidth) auto var(--thinBoxWidth);
    grid-template-rows: var(--boxHeight) auto;
    grid-gap: var(--gridGap);
}

.bio-pic {
    width: var(--boxWidth);
    height: var(--boxHeight);
}

.luna-pic {
    width: var(--thinBoxWidth);
}

.astra-pic {
    width: var(--thinBoxWidth);
    height: calc(100vh - var(--boxHeight));
    object-fit: cover;
}

.about-grid img {
    filter: saturate(0%);
    transition: 1s filter;
}

.about-grid img:hover {
    filter: saturate(100%);
    transition: 1s filter;
}

.about-grid.canvas {
    grid-column: 2 / 5;
}

.about-grid.animal-pics {
    grid-row: span 2;
}

.about-grid p {
    font: var(--fontSize) 'Montserrat', sans-serif;
    color: var(--semi-light);
    letter-spacing: -0.05em;
    line-height: 140%;
}

.about-grid p em {
    color: var(--white);
}

.about-grid .red-heart {
    font-size: var(--heartSize);
    color: red;
}

.about-grid .email-button {
    background-color: var(--black);
    border-radius: 3px;
    width: 100%;
    color: var(--white);
    font: calc(var(--fontSize) * 1.5) 'Bebas Neue', cursive;
    border: 2px solid var(--white);
    margin-top: var(--fontSize);
    padding: 5px;
}

.about-grid .email-button:hover {
    background-color: var(--white);
    color: var(--black);
    transition: 1s all ease;
    cursor: pointer;
}