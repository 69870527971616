@media only screen and (max-width: 799px) {

    .section-about {
        --boxWidth: 50vw;
        --boxHeight: auto;
        --thinBoxWidth: 50vw;
        --gridGap: min(1vw, 10px);
        --heartSize: 28px;
        --fontSize: max(16px, 4vw);
    }

    .about-frame {
        display: grid;
        margin: 0;
        width: auto;
        grid-template-columns: var(--boxWidth) var(--boxWidth);
        grid-template-rows: min-content min-content min-content auto;
        grid-gap: var(--gridGap);
    }

    /* resets */
    /* position grid */
    .about-grid.canvas {
        grid-column: 1 / 2;
        grid-row: 1;
    }

    .about-grid.pic {
        grid-column: 2;
        grid-row: 1;
    }

    .about-grid.bottom-spacer {
        grid-column: 1;
        grid-row: 2;
    }

    .about-grid.paragraph-1 {
        grid-column: 2;
        grid-row: 2;
        padding: 3vw;
    }

    .about-grid.paragraph-2 {
        grid-column: 1;
        grid-row: 3;
        padding: 3vw;
    }

    .about-grid.paragraph-3 {
        grid-column: 1;
        grid-row: 4;
        padding: 3vw;
    }

    .about-grid.animal-pics {
        grid-column: 2;
        grid-row: 3 / 5;
    }

    .luna-pic {
        width: var(--thinBoxWidth);
    }

    .astra-pic {
        width: var(--thinBoxWidth);
        height: calc(100vh - var(--boxHeight));
        object-fit: cover;

    }


    /*.about-grid p {*/
    /*    font: var(--fontSize) 'Montserrat', sans-serif;*/
    /*}*/
    /*.about-grid .red-heart {*/
    /*    font-size: var(--heartSize);*/
    /*}*/

}