/* projects */

.section-projects {
    width: 100%;
    background-color: var(--white);
    --imgOffsetPercent: 0.09;
    /*--picWidth: clamp(100px, min(100vh - 100px, 35vw), 700px);*/
    --picWidth: clamp(500px, min(100vh - 100px, 35vw), 700px);
    height: max-content;
    padding-bottom: 5vh;
}

.projects-header {
    font-family: 'Bebas Neue', cursive;
    font-size: clamp(72px, min(15vh, 12vw), 128px);
    line-height: 89%;
    text-align: center;
    color: var(--black);
}

.section-chroma-gallery {
    /*scroll-snap-align: start;*/
    width: 100%;
    background-color: var(--white);
}

.pic-container {
    width: fit-content;
    margin: 50vh auto 0 auto;
    transform: translate(calc(-100% * var(--imgOffsetPercent)), 0);
    z-index: 10;
}

img.chroma-gallery-pic {
    /*width: clamp(100px, min(100vh - 100px, 35vw), 700px);*/
    width: clamp(100px, min(100vh - 100px, 35vw), 550px);
    transform-origin: bottom left;
    filter: drop-shadow(0px 0px 5px var(--white));
    z-index: 10;
}


.chroma-gallery-container {
    margin-left: max(150px, calc(50vw - 490px));
    width: 515px;
    margin-top: 2vh;
    /*margin-left: calc(50vw - 490px);*/
}

.chroma-gallery-title-container {
    /* this contains the underline */
    width: fit-content;
}

.chroma-gallery-title-container a {
    text-decoration: none;
}

.chroma-gallery-title-container a:hover h1 {
    background: linear-gradient(to right, #FF0000, #FFFF00, #FFFF00, #00FFFF, #3300FF, #FF00FF);
    /*noinspection CssInvalidPropertyValue*/
    background-clip: text;
    color: transparent;
    filter: saturate(0.5) brightness(0.5);
    transition: 1s ease;
}

.chroma-gallery-title {
    position: relative;
    font: clamp(36px, min(10vh, 9vw), 96px) 'Bebas Neue', cursive;

    color: var(--black);
}

/* the chroma underline */
.chroma-gallery-title::after {
    content: "";
    position: absolute;
    /*top: calc(100% - clamp(16px, min(5vw, 8vh), 96px) * 0.17);*/
    top: calc(100% - clamp(16px, min(5vw, 8vh), 96px) * 0.3);
    width: 100%;
    left: 0;
    height: 1px;
    border-radius: 3px;
    background: linear-gradient(to right, #FF0000, #FFFF00, #FFFF00, #00FFFF, #3300FF, #FF00FF);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.chroma-gallery-paragraph-container {
    margin: 5vh auto 0 calc(50vw - var(--picWidth) / 2 - var(--picWidth) * var(--imgOffsetPercent));
    width: calc(var(--picWidth) * var(--imgOffsetPercent) + var(--picWidth) / 2);
}

.chroma-gallery-paragraph {
    margin: 2vh 0 6vh 0;
    font: clamp(16px, min(2vw, 5vh), 21px) 'Montserrat', sans-serif;
    line-height: 140%;
    letter-spacing: -0.035em;
    color: var(--darker);
}

.chroma-gallery-screenshot {
    width: var(--picWidth);
    height: 60vh;
    object-fit: cover;
    object-position: left top;
    z-index: -1;
    border-radius: 3px;
}

.chroma-gallery-screenshot-container {
    position: relative;
    /*margin-left: calc(50vw - 490px);*/
    width: fit-content;
}

.chroma-gallery-pic-sm {
    position: absolute;
    left: calc(100% - (var(--picWidth) / 2 / 2));
    top: calc((var(--picWidth) / 2 / 2) * -1);
    width: calc(var(--picWidth) / 2)
}

.built-panel {
    display: flex;
    flex-direction: column;
    width: max-content;
    height: fit-content;
    background-color: var(--light);
    color: var(--darker);
    font: 600 16px 'Courier New', sans-serif;
    letter-spacing: -0.05em;
    /* styling */
    filter: drop-shadow(0px 0px 4px rgb(0, 0, 0, 0.25));
    border-radius: 3px;
    border: none;
}

.chroma-gallery.built-panel {
    position: absolute;
    left: calc(var(--picWidth) - 30px);
    bottom: 30px;
}

.built-panel h3 {
    padding: 16px;
    color: var(--white);
    background-color: var(--darker);
    border-radius: 3px 3px 0 0;
}

.built-panel ul {
    /*margin: 30px 0 30px 0;*/
    padding: 8px;
}


.built-panel ul li {
    margin-left: 1rem;
    list-style-type: '>';
    padding-inline-start: 8px;
    line-height: 140%;
}

.built-panel a, .built-panel a:hover {
    padding: 8px 16px 16px 16px;
    color: var(--darker);
    text-decoration: underline;
}
