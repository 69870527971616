.section-tagline {
    /*scroll-snap-align: start;*/
    height: 200vh;
    /* testing 2 page scroll */
    /*height: calc(var(--vh) * 100); !* mobile fix for browser navbar shrink *!*/
    width: 100vw;
    max-width: 100%;
    overflow: clip;
}

/* tagline box */
.tagline-container {
    position: absolute;
    /*width: min(98vw, 390px);*/
    width: fit-content;
    height: fit-content;
    /*clip-path: view-box;*/
    /*clip-path: margin-box;*/
    /*transform: translate(calc(50vw - 50%), calc(50vh - 50%)) scale(1);*/
}

.tagline-wrapper {

}

@media only screen and (max-width: 799px) {
    .tagline-container {
        text-shadow: 0 0 5px var(--black);
        /*transform: translate(calc(50vw - 50%), calc(50vh - 50%))*/
    }
}

p.tagline {
    /*position: absolute;*/
    padding-left: min(5vw, 27px);
    opacity: 1;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: min(min(8vh, 8vw), 40px);
    /*font-size: 40px;*/
    line-height: 140%;
    letter-spacing: -0.05em;
    text-align: left;
    z-index: -1;
}

.tagline-bold {
    font-weight: 800;
    color: var(--white);
}

.tagline-thin {
    font-weight: 200;
    color: var(--white);
}

.tagline-dark {
    color: var(--dark);
}

.tagline-small {
    font-size: min(4vw, 20px);
    /*font-size: 20px;*/
    color: var(--dark);
}

