/* main variables and definitions */

/*@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat&display=auto');*/
/*
font-family: 'Bebas Neue', cursive;
font-family: 'Montserrat', sans-serif;
 */

/* desktop */
@import "./navbar.css";
@import "./utilities.css";
@import "./devContainer.css";
@import "./headshot.css";
@import "./downArrow.css";
@import "./tagline.css";
@import "./projects.css";
@import "./hero.css";
@import "./portfolio.css";
@import "./about.css";
@import "./flipBanner.css";
@import "./modal.css";
@import "./logo-dock.css";
@import "./codeExtractor.css";

/* media queries */
@import "./projects-sm.css";
@import "./portfolio-sm.css";
@import "./about-sm.css";
@import "./modal-sm.css";


:root {
    --black: #000000;
    --white: #EAEAEA;
    --light: #D9D9D9;
    --semi-light: #9E9E9E;
    --dark: #777777;
    /*--darker: #555555;*/
    --darker: #454545;
    --windowWidth: 100%;
    --windowHeight: 100%;
    --vh: 10px;
}

html {
    background-color: var(--black);
    color: var(--white);
    font-family: 'Montserrat', sans-serif;
    /*scroll-snap-type: y proximity;*/
    /*scroll-snap-stop: always;*/
    overflow-y: scroll;
}

body {
    overflow-x: clip;
}

/* the app container */
.app-container {
}


.show-on-load {

}


/* utility classes */
